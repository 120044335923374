import fp from 'lodash/fp';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

export const getUserFullName = fp.compose(
  fp.trim,
  fp.join(' '),
  fp.values,
  fp.omitBy(fp.isNil),
  fp.pick(['name', 'first_surname', 'last_surname']),
  fp.get('kyc'),
);

export const getFullName = fp.compose(
  fp.trim,
  fp.join(' '),
  fp.values,
  fp.omitBy(fp.isNil),
  fp.pick(['name', 'first_surname', 'last_surname']),
);

const FINERIO_BANKS = {
  1: 'BBVA Bancomer',
  2: 'Citibanamex',
  7: 'Santander',
  8: 'HSBC',
  9: 'American Express',
  10: 'Invex',
  11: 'Scotiabank',
  12: 'Banorte',
  13: 'Inbursa',
  14: 'Banco Azteca',
  15: 'Liverpool',
  16: 'Bancoppel',
  17: 'Banregio',
  19: 'Hey Banco',
  21: 'Actinver',
  22: 'Compartamos Banco',
  47: 'Banco de Occidente',
};

export const FINANCIAL_RESOURCES = {
  1: 'Aguinaldo o Utilidades',
  2: 'Ahorros',
  3: 'Arrendamiento',
  4: 'Becas',
  5: 'Bonos e Incentivos',
  6: 'Comisiones',
  7: 'Dividendos',
  8: 'Fideicomisos',
  9: 'Fondos de Ahorro',
  10: 'Herencia y/o Donación',
  11: 'Honorarios',
  12: 'Inversiones',
  13: 'Negocio Propio',
  14: 'Pensiones',
  15: 'Por Especificar',
  16: 'Prestamos Personales',
  17: 'Recursos de Terceros',
  18: 'Regalias',
  19: 'Remesas del Extranjero',
  20: 'Rentas',
  21: 'Rifas, Sorteos, Premios',
  22: 'Sucesion Testamentaria',
  23: 'Sueldo Fijo',
  24: 'Ventas',
  25: 'Ventas de Bienes',
  26: 'Nomina',
};

export const OCCUPATIONS = {
  1: 'Empleado',
  2: 'Empresario',
  3: 'Estudiante',
  4: 'Independiente',
  5: 'Jubilado',
  6: 'Otro Especifique',
};

export const VULNERABILITY_ACTIVITIES = {
  1: 'Administración de inmuebles',
  2: 'Administradoras de tarjetas de crédito',
  3: 'Agencia aduanal',
  4: 'Agencias de rifas y sorteos (quinielas y loteria)',
  5: 'Alquiler o renta de automóviles sin chofer',
  6: 'Arrendamiento de inmuebles residenciales',
  7: 'Blindaje de vehículos terrestres y bienes inmuebles',
  8: 'Cartera de gobierno estatal y municipal de estados',
  9: 'Cartera de gobierno federal de estados analíticos',
  10: 'Compra venta de automóviles y camiones nuevos',
  11: 'Compra venta de automóviles y camiones usados',
  12: 'Compra venta de casas y otros inmuebles',
  13: 'Compra venta de otras joyas',
  14: 'Compra venta de relojes',
  15: 'Construcción de inmuebles',
  16: 'Consulado',
  17: 'Departamento del Distrito Federal',
  18: 'Empleado público',
  19: 'Empresas transportadoras de valores',
  20: 'Fabricación de relojes',
  21: 'Galerías de artes y museos',
  22: 'Gobierno estatal',
  23: 'Gobierno extranjero',
  24: 'Gobierno federal',
  25: 'Gobierno municipal',
  26: 'Instituciones financieras del extranjero',
  27: 'Inversionista',
  28: 'No clasificada',
  29: 'Organizaciones religiosas',
  30: 'Prestación de servicios públicos y sociales',
  31: 'Renta de vehículos aéreos',
  32: 'Servicio de corredores de bienes raíces',
  33: 'Servicios de bufetes jurídicos',
  34: 'Servicios de contaduría y auditoría incluso tened',
  35: 'Servicios de notarías públicas',
  36: 'Servicios de oficinas y representaciones de otros',
  37: 'Servicios de reparación de relojes y joyas',
  38: 'Tallado de piedras preciosas',
  39: 'Usuarios menores de gobierno estatal y municipal',
  40: 'Usuarios menores de gobierno federal',
  41: 'Usuarios menores de servicios profesionales y técnicos',
};

export const INVESTMENT_RESOURCES = {
  1: 'Propios',
  2: 'De Terceros',
};

export const OCCUPATION_YEAR = {
  1: 'Menos de un año',
  2: '1 a 5 años',
  3: '6 a 10 años',
  4: 'Más de 10 años',
};

export const getAddressProof = fp.get('address_proof');
export const getBeneficiaries = fp.get('beneficiaries');
export const getBirthCountry = fp.get('birth_country');
export const getNationality = fp.get('nationality');
export const getBirthDate = fp.get('birth_date');
export const getColony = fp.get('colony');
export const getCity = fp.get('city');
export const getEmail = fp.get('email');
export const getExteriorNumber = fp.get('ext_num');
export const getFirstSurname = fp.get('first_surname');
export const getFinerioBank = fp.curry((id?: number): string => {
  if (!fp.isNil(id)) return fp.get(id, FINERIO_BANKS);
  return '-';
});
export const getFinerioCustomerId = fp.get('finerio_customer');
export const getFinerioCredentialId = fp.compose(fp.get('id'), fp.head);
export const getGender = fp.get('gender');
export const getId = fp.get('id');
export const getInteriorNumber = fp.get('int_num');
export const getIsAccredited = fp.get('is_accredited');
export const getKeyRegistry = fp.get('key_registry');
export const getFederalEntity = fp.get('federal_entity');
export const getKycStage = fp.get('kyc_stage');
export const getLastSurname = fp.get('last_surname');
export const getAvatar = fp.get('avatar');
export const getMunicipality = fp.get('municipality');
export const getName = fp.get('name');
export const getType = fp.get('type');
export const getBalance = fp.get('balance');
export const getCurrency = fp.get('currency');
export const getOfficialIdBack = fp.get('official_id_back');
export const getOfficialIdFront = fp.get('official_id_front');
export const getOfficialIdNumber = fp.get('official_id_number');
export const getPhone = fp.get('phone');
export const getPercentage = fp.get('percentage');
export const getRecordNumber = fp.get('id');
export const getReferences = fp.get('references');
export const getResidenceCountry = fp.get('residence_country');
export const getStreet = fp.get('street');
export const getTaxId = fp.get('tax_id');
export const getGradeLevel = (kyc): string | null => {
  const {
    is_politically_exposed = null,
    monthly_amount = null,
    residence_country = null,
    nationality = null,
  } = kyc;
  if (is_politically_exposed && residence_country !== 'MX') {
    return 'Alto';
  }
  if (
    (is_politically_exposed && residence_country === 'MX') ||
    residence_country !== 'MX'
  ) {
    if (monthly_amount) {
      const [start, end] = monthly_amount.split('-').map(Number);
      if (start > 14000 || end > 14000) {
        return 'Medio';
      }
    } else {
      return 'NA';
    }
  }
  return 'Bajo';
};
export const getZipCode = fp.get('zip_code');
export const getLifeProof = fp.get('life_proof');
export const getRisks = fp.get('risks');
export const getFinancialResources = fp.get('financial_resource_origin');
export const getOccupation = fp.get('occupation');
export const getVulnerableActivity = fp.get('vulnerable_activity');
export const getActivity = fp.get('activity');
export const getExternalTaxId = fp.get('external_tax_id');
export const getExternalTaxIdCountry = fp.get('external_tax_id_country');
export const getInvestmentResource = fp.get('investment_resource');
export const getPoliticallyExposed = fp.get('is_politically_exposed');
export const getPoliticallyExposedDescription = fp.get(
  'politically_exposed_description',
);
export const getAccreditationDate = fp.get('accreditation_date');
export const getOccupationYear = fp.get('occupation_year');
export const getIpAddress = fp.get('ip_address');
export const getInvestmentReason = fp.get('investment_reason');
export const getInvestorConsent = fp.get('investor_consent');
export const getOperationReason = fp.get('operation_reason');
export const getMonthlyAmount = fp.get('monthly_amount');
export const getMonthlyOperations = fp.get('monthly_operations');
export const getlastUpdate = fp.get('kyc_update_date');

export enum KYCStagesEnum {
  PHONE_NUMBER = 2,
  CONFIRM_PHONE_NUMBER = 3,
  PHONE_NUMBER_SUCCESS = 4,
  LIFE_PROOF = 5,
  PERSONAL_INFORMATION = 6,
  OFFICIAL_IDENTIFICATION = 7,
  PERSONAL_INFORMATION_SUCCESS = 8,
  ADDRESS_INFORMATION = 9,
  ADDRESS_INFORMATION_SUCCESS = 10,
  PROFESSIONAL_INFORMATION = 11,
  PROFESSIONAL_INFORMATION_SUCCESS = 12,
  BANK_INFORMATION = 13,
  BANK_INFORMATION_SUCCESS = 14,
  BENEFICIARIES = 15,
  BENEFICIARIES_SUCCESS = 16,
  REFERENCES = 17,
  REFERENCES_SUCCESS = 18,
  RISKS = 19,
  RISKS_SUCCESS = 20,
  KYC_PHYSICAL_COMPLETE = 21,
}

export const trimTextToWords = (text: string, wordLimit: number): string => {
  if (!text) return '-';

  const words = text.split(' ');

  if (words.length <= wordLimit) {
    return text;
  }

  return `${words.slice(0, wordLimit).join(' ')}...`;
};
